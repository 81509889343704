import React, { useRef, useEffect, useState } from "react"
import styled from 'styled-components';
import Seo from "../components/seo"
import Logo from '../assets/logo_vertual_compact.svg'
import Play from '../assets/play.svg'
import PosterHomepage1 from "../images/poster/homepage1.jpg";

const VideoWrapper = styled.div`

.video {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
		background-color: #050608;
        cursor: auto;}

    .video video {
        position: absolute;
        z-index: 1;
        width: 100%;
        height: 100%;
        }

    .play{
        display:${props => props.display ? "flex" : "none"};
        justify-content:center;
        align-items:center;
        position: absolute;
        z-index: 10;
        width: 100%;
        height: 100%;
        cursor:pointer;
    }

    .play svg{
        width:10rem;
        height:10rem;
        fill: #fff;
        cursor:pointer;
    }
`

const StyledLogo = styled.div`
svg{
    height: 2.5rem;
    position: fixed;
    top: 2rem;
    left: 3rem;
    fill: ${props => props.white ? '#fff;' : '#181718;'};
    cursor:pointer;
}

@media (max-width:990px){
    svg{
    left: 2rem;
    }
}

@media (max-width:512px){
    svg{
    height: 34px;
    top: 1.25rem;
    left: 1.25rem;
}
}
`;


// markup
const ReelPage = () => {
    let poster, videoElem;
    const ref = useRef(null);
    const [playIcon, setPlayIcon] = useState(true);

    const Handler = () => {
        videoElem.play();
        setPlayIcon(false);
    }

    useEffect(() => {
        const element = ref.current;
        videoElem = element.querySelector('video')
    }, []);

    return (
        <>
            <div ref={ref}>
                <Seo title="Gucci Experience Reel - Vertual" />
                <StyledLogo white={true}> <Logo /></StyledLogo>

                <VideoWrapper display={playIcon}>
                    <div className="play" onClick={Handler}>
                        <Play />
                    </div>
                    <div className="video">
                        <video src="https://player.vimeo.com/external/571817617.hd.mp4?s=0dbbe3c7b85f49ecb2454fde7adf746d0df31bcf&profile_id=175" autoPlay controlsList="nodownload" controls loop playsInline poster={PosterHomepage1}></video>
                    </div>
                </VideoWrapper>
            </div>
        </>
    )
}

export default ReelPage

